import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { TReactSelectOption } from '../../types/additionalTypes'
import { useTranslation } from 'react-i18next'

interface Props {
  value: any
  options: TReactSelectOption[]
  onChange: (values: any) => void
  isClearable?: boolean
  selectId?: string
  inputId?: string
  containerStyles: string
  controlFocusedStyles: string
  controlDefaultStyles: string
  inputStyles: string
  dropdownIndicatorStyles?: string
  disableSort?: boolean
}

export const StockItemsSelect: React.FC<Props> = ({
  selectId,
  inputId,
  value,
  options,
  onChange,
  containerStyles,
  controlFocusedStyles,
  controlDefaultStyles,
  inputStyles,
  dropdownIndicatorStyles,
}) => {
  const [selectOptions, setSelectOptions] = useState<TReactSelectOption[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    setSelectOptions(options)
  }, [options])

  const getValue = useMemo(() => {
    return value
      ? value.map((opt: number) => {
          const option = selectOptions.find((option) => option.value === opt)
          return {
            label: option?.label,
            id: option?.id,
            value: option?.value,
          }
        })
      : value
  }, [value, selectOptions])

  return (
    <Select
      isMulti
      id={selectId}
      inputId={inputId}
      value={getValue}
      placeholder={`${t('Select')}...`}
      classNames={{
        container: () => containerStyles,
        control: (state) => (state.isFocused ? controlFocusedStyles : controlDefaultStyles),
        input: () => inputStyles,
        dropdownIndicator: () => dropdownIndicatorStyles || '',
        valueContainer: () => '',
        multiValue: () => 'h-[25px] text-sm',
      }}
      options={selectOptions}
      onChange={(e) => onChange(e.map((opt) => opt.value))}
    />
  )
}
