import React, { useRef, useState } from 'react'
import { Modal } from '../../modal'
import { IUploadSignatureData, useOfferAgreements } from '../../../hooks/useOfferAgreements'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../defaultButton'
import SignatureCanvas from 'react-signature-canvas'
import { useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'

interface Props {
  showed: boolean
  closeModal: () => void
  agreement: IUploadSignatureData['agreement']
  isConsignedReturn?: boolean
  isAcquiredReturn?: boolean
  isUnmounting?: boolean
}

export const SignatureModal: React.FC<Props> = ({
  showed,
  closeModal,
  agreement,
  isConsignedReturn = false,
  isAcquiredReturn = false,
  isUnmounting = false,
}) => {
  const { t } = useTranslation()
  const { uploadSignature } = useOfferAgreements()
  const canvas = useRef<any>(null)
  const [sign, setSign] = useState('')
  const consignmentReturn = useSelector((store: IStore) => store.consignmentReturn)
  const acquiredReturn = useSelector((store: IStore) => store.acquiredReturn)
  const agreements = useSelector((store: IStore) => store.agreements)

  const onClose = () => {
    canvas.current.clear()
    closeModal()
  }

  const onCancel = () => {
    // @ts-ignore
    canvas.current.clear()
    closeModal()
  }

  const onClear = () => {
    // @ts-ignore
    canvas.current.clear()
  }

  const onSave = async () => {
    await uploadSignature({
      signature: sign,
      agreement: agreement,
      items: isAcquiredReturn
        ? acquiredReturn.returnItems.map((items) => (items.id ? items.id : 0))
        : isConsignedReturn
          ? consignmentReturn.returnItems.map((items) => (items.id ? items.id : 0))
          : undefined,
      is_unmounting: agreement === 'consignment' && isUnmounting ? true : false,
    })
    closeModal()
  }

  return (
    <Modal showed={showed} bodyStyle='w-[1080px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      <div className='flex flex-col gap-6'>
        <div className='w-full flex items-center pr-2 md:pr-12 text-zinc-800 text-[28px] leading-9 font-medium'>
          {t('Your Signature')}
        </div>

        {showed && (
          <div className=' rounded-lg border border-zinc-400 border-dashed p-6 pt-1 pb-3'>
            <SignatureCanvas
              canvasProps={{
                height: 360,
                className: 'w-full border-b border-zinc-400',
              }}
              onEnd={() => {
                if (canvas?.current?.isEmpty()) return null
                setSign(canvas?.current?.toDataURL())
              }}
              ref={canvas}
            />
            <div className='text-lg text-zinc-400 mt-3 text-center'>{t('Draw signature')}</div>
          </div>
        )}

        <div className='flex flex-col justify-between md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
          <DefaultButton
            title={t('Clear signature')}
            classname='text_button !text-red-400 w-full md:w-[200px]'
            disabled={agreements.uploadSignatureLoading}
            onClick={onClear}
          />
          <div className='flex flex-col justify-end md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
            <DefaultButton
              title={t('Cancel')}
              classname='text_button w-full md:w-[200px]'
              disabled={agreements.uploadSignatureLoading}
              onClick={onCancel}
            />
            <DefaultButton
              title={t('Done')}
              classname='main_button w-full md:w-[200px]'
              disabled={agreements.uploadSignatureLoading || !sign}
              onClick={onSave}
              loading={agreements.uploadSignatureLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
