import React, { useEffect, useState } from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { IAcquiredReturnItem } from '../../../types/acquiredReturnTypes'
import { getCurrency } from '../../../utils/getCurrency'
import { StockItemsSelect } from '../../stockItemsSelect'
import { setAcquiredReturnItems } from '../../../store/acquiredReturnSlice'

interface Props {
  showed: boolean
  closeModal: () => void
  onItemsSave: (items: IAcquiredReturnItem[]) => void
}

export const AcquiredReturnItemsConfirmModal: React.FC<Props> = ({ showed, closeModal, onItemsSave }) => {
  const { t } = useTranslation()
  const acquiredReturn = useSelector((store: IStore) => store.acquiredReturn)
  const { returnItems, stockItems } = acquiredReturn
  const [selectedItems, setSelectedItems] = useState<IAcquiredReturnItem[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (returnItems) {
      const items = returnItems.map((item) => {
        return {
          ...item,
          ns_items: stockItems
            .filter((stockItem) => stockItem.items_id.includes(item.id))
            .map((stockItem2) => stockItem2.id),
        }
      })
      setSelectedItems(items)
    }
  }, [returnItems, stockItems])

  const onCancel = () => {
    closeModal()
  }

  const onSave = () => {
    dispatch(setAcquiredReturnItems(selectedItems))
    onItemsSave(selectedItems)
    closeModal()
  }

  const selectOptions = stockItems.map((opt) => {
    return {
      label: opt.sku,
      id: opt.id,
      value: opt.id,
    }
  })

  const editField = (value: number[], index: number) => {
    let newArr = [...selectedItems]

    if (newArr && newArr[index]) {
      const item = { ...newArr[index] }
      newArr[index] = {
        ...item,
        ns_items: value,
      }
    }
    setSelectedItems(newArr)
  }

  return (
    <Modal showed={showed} bodyStyle='w-[1080px] !max-w-[95vw] xl:!max-w-[1080px] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={closeModal}>
          <IconClose />
        </button>
      </div>
      <div className='flex flex-col justify-between pt-4'>
        <div className='flex flex-col gap-6 h-[60vh] overflow-y-auto custom_scroll'>
          <div className='text-[28px] leading-9 font-medium text-zinc-800'>{t('Select Stocked Items to return')}</div>

          <div className='flex flex-col gap-4'>
            {selectedItems.map((item, index) => {
              return (
                <div key={item.id} className='w-full flex items-center justify-between gap-2'>
                  <div className='flex items-center'>
                    {item.amount ? getCurrency(item.amount, item.currency) : null} {item.description}
                  </div>

                  <StockItemsSelect
                    selectId='select-item-subproduct'
                    inputId='input-item-subproduct'
                    options={selectOptions}
                    onChange={(value) => editField(value, index)}
                    value={item.ns_items}
                    containerStyles='w-[250px] min-w-[250px] min-h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused !h-auto'
                    controlDefaultStyles={`form-select !h-auto`}
                    inputStyles='!text-zinc-900'
                  />
                </div>
              )
            })}
          </div>
        </div>

        <div className='flex flex-col justify-end md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
          <button type='button' className='text_button !text-red-400' onClick={onCancel}>
            {t('Cancel')}
          </button>
          <button type='button' className='main_button w-full md:w-[200px]' onClick={onSave}>
            {t('Select')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
