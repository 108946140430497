import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['acquiredReturn'] = {
  acquired_amount: '',
  currency: 'USD',
  id: 0,
  internal_notes: [],
  status: '',
  items: [],
  stockItems: [],
  returnItems: [],
}

export const acquiredReturnSlice = createSlice({
  name: 'acquiredReturn',
  initialState,
  reducers: {
    setAcquiredReturnInfo: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setAcquiredReturnItems: (state, action) => {
      return {
        ...state,
        returnItems: action.payload,
      }
    },
    setAcquiredReturnNotes: (state, action) => {
      return {
        ...state,
        internal_notes: action.payload,
      }
    },
    addAcquiredReturnInternalNote: (state, action) => {
      return {
        ...state,
        internal_notes: [...state.internal_notes, action.payload],
      }
    },
    deleteAcquiredReturnNote: (state, action) => {
      return {
        ...state,
        internal_notes: state.internal_notes.filter((note) => note.id !== action.payload.id),
      }
    },
  },
})

export const {
  setAcquiredReturnInfo,
  setAcquiredReturnItems,
  setAcquiredReturnNotes,
  addAcquiredReturnInternalNote,
  deleteAcquiredReturnNote,
} = acquiredReturnSlice.actions

export default acquiredReturnSlice.reducer
