import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../modal'
import { DefaultButton } from '../../defaultButton'
import { useGetStepData } from '../../../hooks/useGetStepData'
import { useDispatch, useSelector } from 'react-redux'
import { clearShowAgreementsWarn } from '../../../store/showAgreementsWarnSlice'
import { IStore } from '../../../types/storeTypes'
import { useGetConsignedStepData } from '../../../hooks/useGetConsignedStepData'
import { useGetConsignedReturnStepData } from '../../../hooks/useGetConsignedReturnStepData'
import { useGetAcquiredReturnStepData } from '../../../hooks/useGetAcquiredReturnStepData'
import { useOfferAgreements } from '../../../hooks/useOfferAgreements'

export const AgreementsWarnModal = ({
  isConsignedConvert = false,
  isAcquiredReturn = false,
  isConsignedReturn = false,
}) => {
  const { t } = useTranslation()
  const { changeCurrentStep } = useGetStepData()
  const { changeCurrentStep: changeConsignedCurrentStep } = useGetConsignedStepData()
  const { changeCurrentStep: changeConsignedReturnStep } = useGetConsignedReturnStepData()
  const { changeCurrentStep: changeAcquiredReturnStep } = useGetAcquiredReturnStepData()
  const dispatch = useDispatch()
  const showAgreementsWarn = useSelector((store: IStore) => store.showAgreementsWarn)
  const agreements = useSelector((store: IStore) => store.agreements)
  const acquiredReturn = useSelector((store: IStore) => store.acquiredReturn)
  const { deleteSignature } = useOfferAgreements()

  const onCancel = () => {
    dispatch(clearShowAgreementsWarn())
  }
  const onBack = () => {
    if (showAgreementsWarn.nextStep) {
      if (isConsignedConvert) {
        changeConsignedCurrentStep(showAgreementsWarn.nextStep, undefined, true)
      } else if (isAcquiredReturn) {
        changeAcquiredReturnStep(showAgreementsWarn.nextStep, undefined, true)
      } else if (isConsignedReturn) {
        changeConsignedReturnStep(showAgreementsWarn.nextStep, undefined, true)
      } else {
        changeCurrentStep(showAgreementsWarn.nextStep, undefined, true)
      }
    }
    dispatch(clearShowAgreementsWarn())
  }

  const onBackClick = async () => {
    if (isAcquiredReturn && agreements.return_form) {
      const items = acquiredReturn.returnItems.map((item) => (item.id ? item.id : 0))
      await deleteSignature('return', items)
    }
    onBack()
  }

  return (
    <Modal showed={showAgreementsWarn.showed} bodyStyle='!max-w-[600px]'>
      <div className='flex flex-col space-y-12'>
        <div className={`text-[28px] leading-9 font-medium font-sans text-start`}>
          {t('Are you sure you want to go back?')}
          <div className='text-[24px] font-normal mt-4'>
            {t('Any signed documents will be deleted and need to be re-signed when you return to the signature step.')}
          </div>
        </div>
        <div className='flex justify-end flex-row gap-4 md:gap-8 w-full [&>div]:w-1/2'>
          <DefaultButton title={t('Cancel')} classname='text_button !text-red-400 w-full' onClick={onCancel} />
          <DefaultButton title={t('Yes, go back')} classname='main_button w-full' onClick={onBackClick} />
        </div>
      </div>
    </Modal>
  )
}
