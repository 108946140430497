import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { IPaymentInfo, IStore } from '../../../types/storeTypes'
import { DefaultButton } from '../../defaultButton'
import { callApi } from '../../../utils/callApi'
import { useDispatch, useSelector } from 'react-redux'
import { showGlobalPopup } from '../../../store/globalPopupSlice'
import { setGlobalPaymentData } from '../../../store/paymentInfoSlice'
import { BankInfoBlock } from '../../formBlocks/bankInfoBlock'

interface Props {
  showed: boolean
  closeModal: () => void
}

export const EditPaymentInfoModal: React.FC<Props> = ({ showed, closeModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalPaymentInfo, setModalPaymentInfo] = useState<IPaymentInfo['payment_data'] | null>(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const paymentInfo = useSelector((store: IStore) => store.paymentInfo)
  const client = useSelector((store: IStore) => store.client)

  useEffect(() => {
    if (showed) setModalPaymentInfo({ ...paymentInfo.payment_data, payee_name: client.full_name })
  }, [paymentInfo.payment_data, client.full_name, showed])

  const onSave = async () => {
    let success = true
    try {
      setSaveLoading(true)
      const data = {
        payee_name: modalPaymentInfo?.payee_name,
        account_number: modalPaymentInfo?.account_number,
        routing_number: modalPaymentInfo?.routing_number,
        plaid_account_id: modalPaymentInfo?.plaid_account_id,
        plaid_public_token: modalPaymentInfo?.plaid_public_token,
      }
      await callApi({
        method: 'POST',
        url: `/client/payment`,
        data,
        isDropOff: true,
      })
      dispatch(
        setGlobalPaymentData({
          ...paymentInfo.payment_data,
          ...data,
        })
      )
    } catch (error) {
      console.log(error, 'error data')
      success = false
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      if (success) onClose()
      setSaveLoading(false)
    }
  }

  const onClose = () => {
    closeModal()
    setModalPaymentInfo(null)
  }

  return (
    <Modal showed={showed} bodyStyle='!w-[600px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      <div className='font-medium text-[28px] leading-9 text-zinc-800 mb-2'>
        {paymentInfo.payment_data?.account_number
          ? t('Edit {{name}} bank account information', {
              name: client.full_name,
            })
          : t('Add {{name}} bank account information', {
              name: client.full_name,
            })}
      </div>
      <div className='flex flex-col gap-6 max-h-[80vh] overflow-y-auto custom_scroll mx-[-16px]'>
        <BankInfoBlock paymentData={modalPaymentInfo} setPaymentData={setModalPaymentInfo} hideTitle hidePromo />

        <div className='flex justify-end px-4'>
          <DefaultButton
            title={t('Save')}
            onClick={() => onSave()}
            disabled={
              saveLoading ||
              !modalPaymentInfo?.account_number ||
              !modalPaymentInfo.routing_number ||
              !modalPaymentInfo.payee_name
            }
            classname='main_button w-[200px]'
            loading={saveLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
