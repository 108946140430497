import { useDispatch, useSelector } from 'react-redux'
import { ApiRoutes } from '../utils/apiRoutes'
import { callApi } from '../utils/callApi'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { serverMode } from '../utils/consts'
import { IStore } from '../types/storeTypes'
import { setIntermediateSave } from '../store/appointmentInfoSlice'
import { useState } from 'react'

export const useFormSession = () => {
  const dispatch = useDispatch()
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const { intermediate_save } = appointmentInfo
  let checkInterval: NodeJS.Timeout | null = null
  const [saveSessionLoading, setSaveSessionLoading] = useState(false)
  const [stopCheckSessionLoading, setStopCheckSessionLoading] = useState(false)

  const checkSession = async () => {
    try {
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.lockForm}`,
      })
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
      if (checkInterval) clearInterval(checkInterval)
    } finally {
    }
  }

  const saveSession = async (data: any) => {
    setSaveSessionLoading(true)
    const saveData = {
      data: {
        ...intermediate_save,
        ...data,
      },
    }
    try {
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.saveForm}`,
        data: saveData,
      })

      dispatch(setIntermediateSave(saveData.data))
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveSessionLoading(false)
    }
  }

  const saveConsignmentConversionSession = async (data: any) => {
    setSaveSessionLoading(true)
    const saveData = {
      data: {
        ...intermediate_save,
        consignmentConversion: {
          ...intermediate_save?.consignmentConversion,
          ...data,
        },
      },
    }
    try {
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.saveForm}`,
        data: saveData,
        isConsignedConvert: true,
      })

      dispatch(setIntermediateSave(saveData.data))
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveSessionLoading(false)
    }
  }

  const saveConsignmentReturnSession = async (data: any) => {
    setSaveSessionLoading(true)
    const saveData = {
      data: {
        ...intermediate_save,
        consignmentReturn: {
          ...intermediate_save?.consignmentReturn,
          ...data,
        },
      },
    }
    try {
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.saveForm}`,
        data: saveData,
        isConsignedReturn: true,
      })

      dispatch(setIntermediateSave(saveData.data))
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveSessionLoading(false)
    }
  }

  const saveAcquiredReturnSession = async (data: any) => {
    setSaveSessionLoading(true)
    const saveData = {
      data: {
        ...intermediate_save,
        acquiredReturn: {
          ...intermediate_save?.acquiredReturn,
          ...data,
        },
      },
    }
    try {
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.saveForm}`,
        data: saveData,
        isConsignedReturn: true,
      })

      dispatch(setIntermediateSave(saveData.data))
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveSessionLoading(false)
    }
  }

  const startCheckSession = () => {
    if (checkInterval) clearInterval(checkInterval)
    if (serverMode !== 'local') {
      checkInterval = setInterval(() => {
        return checkSession()
      }, 30000)
    }
  }

  const stopCheckSession = () => {
    if (checkInterval) clearInterval(checkInterval)
  }

  const stopCheckSessionRequest = async () => {
    setStopCheckSessionLoading(true)
    try {
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.unlockForm}`,
      })
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
      if (checkInterval) clearInterval(checkInterval)
    } finally {
      setStopCheckSessionLoading(false)
    }
  }

  return {
    startCheckSession,
    stopCheckSession,
    saveSession,
    saveSessionLoading,
    stopCheckSessionRequest,
    stopCheckSessionLoading,
    saveConsignmentConversionSession,
    saveConsignmentReturnSession,
    saveAcquiredReturnSession,
  }
}
