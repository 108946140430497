import React, { useEffect, useState } from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { DefaultButton } from '../../defaultButton'

interface Props {
  showed: boolean
  closeModal: () => void
  onContinue: (stock_exactly: boolean, cb: () => void) => void
}

export const ConsignedConvertStockTypeModal: React.FC<Props> = ({ showed, closeModal, onContinue }) => {
  const { t } = useTranslation()
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const [stockExactly, setStockExactly] = useState<boolean | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const info = appointmentInfo.intermediate_save?.consignmentConversion?.stock_exactly
    if (info !== undefined) {
      setStockExactly(info)
    }
  }, [appointmentInfo.intermediate_save?.consignmentConversion?.stock_exactly])

  const onCancel = () => {
    setStockExactly(null)
    closeModal()
  }

  const onSave = () => {
    setLoading(true)
    if (stockExactly !== null)
      onContinue(stockExactly, () => {
        closeModal()
        setLoading(false)
      })
  }

  return (
    <Modal showed={showed} bodyStyle='w-[960px] !max-w-[95vw] xl:!max-w-[960px] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={closeModal}>
          <IconClose />
        </button>
      </div>
      <div className='flex flex-col justify-between pt-4'>
        <div className='flex flex-col gap-6 h-[250px] overflow-y-auto custom_scroll'>
          <div className='text-[28px] leading-9 font-medium text-zinc-800'>
            {t("Please select the way you're going to stock this offer:")}
          </div>

          <div className='flex flex-col gap-4'>
            <div className='w-full flex items-center gap-2'>
              <input
                type='radio'
                name={`stock_exactly-true`}
                id={`input-stock_exactly-true`}
                checked={stockExactly !== null && !!stockExactly}
                onChange={() => setStockExactly(true)}
                className={`form-input w-[24px] h-[24px] min-w-[24px] accent-green-700`}
                autoComplete='false'
              />
              <label htmlFor={`input-stock_exactly-true`} className='flex text-lg text-zinc-800'>
                {t(
                  'Stock exactly as items appear on the purchase form. These items CANNOT be split, combined, or deleted.'
                )}
              </label>
            </div>

            <div className='w-full flex items-center gap-2'>
              <input
                type='radio'
                name={`stock_exactly-false`}
                id={`input-stock_exactly-false`}
                checked={stockExactly !== null && !stockExactly}
                onChange={() => setStockExactly(false)}
                className={`form-input w-[24px] h-[24px] min-w-[24px] accent-green-700`}
                autoComplete='false'
              />
              <label htmlFor={`input-stock_exactly-false`} className='flex text-lg text-zinc-800'>
                {t('Stock from scratch so that items can be split, combined or deleted.')}
              </label>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-end md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
          <DefaultButton
            title={t('Cancel')}
            onClick={onCancel}
            classname='text_button !text-red-400 w-full md:w-[200px]'
            disabled={loading}
          />

          <DefaultButton
            title={t('Continue')}
            onClick={onSave}
            classname='main_button w-full md:w-[200px]'
            loading={loading}
            disabled={stockExactly === null || loading}
          />
        </div>
      </div>
    </Modal>
  )
}
