import axios, { AxiosPromise } from 'axios'
import { API_URL, LOCAL_ACCESS_TOKEN, serverMode, SESSION_TOKEN } from './consts'

interface Props {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  url: string
  data?: Object | null
  params?: object
  signal?: any
  responseType?: 'blob' | 'arraybuffer'
  isPurchaseForm?: boolean
  isConsignedConvert?: boolean
  isConsignedReturn?: boolean
  isAcquiredReturn?: boolean
  isDropOff?: boolean
  v1?: boolean
}

export const callApi = async ({
  method,
  url,
  data = null,
  params,
  signal,
  responseType,
  isPurchaseForm = false,
  isConsignedConvert = false,
  isConsignedReturn = false,
  isAcquiredReturn = false,
  isDropOff = false,
  v1 = false,
}: Props): Promise<AxiosPromise<any>> => {
  try {
    let sendData: any = data
    let sendParams: any = params

    const currentLang = window.location.pathname.includes('/es/')
      ? 'es'
      : window.location.pathname.includes('/ca/')
        ? 'ca'
        : 'en'

    let headers = {
      'Content-Type': 'application/json',
      Authorization: '',
      'Accept-Language': currentLang,
    }

    const token = sessionStorage.getItem(LOCAL_ACCESS_TOKEN)
    const sessionId = sessionStorage.getItem(SESSION_TOKEN) || ''

    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` }
    }

    if (serverMode !== 'local') {
      if (method === 'GET') {
        sendParams = {
          ...sendParams,
          session_token: sessionId,
        }
      } else {
        sendData = {
          ...sendData,
          session_token: sessionId,
        }
      }
    }

    let api_uri = API_URL
    let path = '/purchase-form'
    if (isConsignedConvert || (window.location && window.location.pathname.includes('/consignment-conversion'))) {
      path = '/consignment-convert'
    }
    if (isConsignedReturn || (window.location && window.location.pathname.includes('/consignment-return'))) {
      path = '/consignment-return'
    }
    if (isAcquiredReturn || (window.location && window.location.pathname.includes('/acquired-return'))) {
      path = '/acquired-return'
    }
    if (isDropOff) {
      path = '/purchase-form-dropoff'
    }
    if (v1) path = '/v1'
    if (isPurchaseForm) path = '/purchase-form'

    return await axios({
      method: method,
      url: api_uri + path + url,
      data: sendData,
      headers,
      params: sendParams,
      signal,
      responseType,
    })
  } catch (e: any) {
    console.log('-> e', e)
    if (
      e?.response?.status === 401 &&
      e.response?.data &&
      e.response.data?.message &&
      e.response?.data &&
      e.response.data.message === 'Token has expired'
    ) {
      console.log(e)
      // sessionStorage.removeItem(LOCAL_ACCESS_TOKEN)
      // eslint-disable-next-line no-throw-literal
      throw 'Your session has been expired. Please return back to the Appointment card in Admin and click on Continue appointment. If Continue appointment button is not there, reopen the appointment card.'
    }
    if (e?.response && e.response?.data && e.response.data?.message) throw e.response.data.message
    else if (e?.response && e.response?.data && e.response.data?.error) throw e.response.data.error
    else throw e?.message
  }
}
