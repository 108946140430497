import { useDispatch } from 'react-redux'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { useState } from 'react'

export const useSignaturePad = (onSave: (sign: string) => void) => {
  const [signaturePadLoading, setSignaturePadLoading] = useState(false)
  const dispatch = useDispatch()

  const startSign = () => {
    setSignaturePadLoading(true)
    const isInstalled = document.documentElement.getAttribute('SigPlusExtLiteExtension-installed')
    document.removeEventListener('SignResponse', signResponse)

    if (!isInstalled) {
      const errorText =
        'SigPlusExtLite extension is either not installed or disabled. Please install or enable extension.'
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: errorText }))
      setSignaturePadLoading(false)
      // alert(errorText)
      return
    }

    // const canvasObj = document.getElementById('cnv')
    // if (canvasObj) {
    //   /// @ts-ignore
    //   canvasObj.getContext('2d').clearRect(0, 0, canvasObj.width, canvasObj.height)
    //   /// @ts-ignore
    //   imgWidth = canvasObj.width
    //   /// @ts-ignore
    //   imgHeight = canvasObj.height
    // }

    const message = {
      firstName: '',
      lastName: '',
      eMail: '',
      location: '',
      imageFormat: 1,
      imageX: 0,
      imageY: 0,
      imageTransparency: false,
      imageScaling: false,
      maxUpScalePercent: 0.0,
      rawDataFormat: 'ENC',
      minSigPoints: 25,
    }

    // eslint-disable-next-line no-restricted-globals
    if (top) top.document.addEventListener('SignResponse', signResponse, { once: true })
    const messageData = JSON.stringify(message)
    const element = document.createElement('MyExtensionDataElement')
    element.setAttribute('messageAttribute', messageData)
    document.documentElement.appendChild(element)
    const evt = document.createEvent('Events')
    evt.initEvent('SignStartEvent', true, false)
    element.dispatchEvent(evt)
    setTimeout(() => {
      setSignaturePadLoading(false)
    }, 1000)
  }

  const signResponse = (event: any) => {
    const str = event.target.getAttribute('msgAttribute')
    const obj = JSON.parse(str)
    SetValues(obj)
  }

  const SetValues = (objResponse: string) => {
    let obj = null
    if (typeof objResponse === 'string') {
      obj = JSON.parse(objResponse)
    } else {
      obj = JSON.parse(JSON.stringify(objResponse))
    }

    if (obj.errorMsg != null && obj.errorMsg !== '' && obj.errorMsg !== 'undefined') {
      if (!obj.errorMsg.includes('User cancelled signing.')) {
        dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: obj.errorMsg }))
      }
    } else {
      if (obj.isSigned) {
        const signData = 'data:image/png;base64,' + obj.imageData
        onSave(signData)
      }
    }
  }

  return {
    startSign,
    signaturePadLoading,
  }
}
