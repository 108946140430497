import { useFormSession } from './useFormSession'
import { useDispatch } from 'react-redux'
import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { showGlobalPopup } from '../store/globalPopupSlice'
import {
  setAgreementsConsignment,
  setAgreementsOffer,
  setAgreementsPurchase,
  setAgreementsReturn,
  setDeleteSignatureLoading,
  setUploadSignatureLoading,
} from '../store/agreementsSlice'
import { useTranslation } from 'react-i18next'

export interface IUploadSignatureData {
  files?: {
    url: string
    name: string
  }[]
  agreement: 'purchase' | 'consignment' | 'offer' | 'return' | 'contract'
  signature: string
  items?: number[]
  is_unmounting?: boolean
}

export const useOfferAgreements = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { startCheckSession, stopCheckSession, saveSession } = useFormSession()

  const uploadSignature = async (
    data: IUploadSignatureData,
    isConsignedConvert = false,
    isConsignedReturn = false,
    isAcquiredReturn = false,
    notCheck = false
  ) => {
    stopCheckSession()
    dispatch(setUploadSignatureLoading(true))
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.upload}?lang=${i18n.language}`,
        data,
        isConsignedConvert,
        isConsignedReturn,
        isAcquiredReturn,
      })

      if (resp && resp.data) {
        const url = resp.data.url

        if (data.agreement === 'consignment' && url) {
          dispatch(setAgreementsConsignment(url))
          saveSession({ is_unmounting: data.is_unmounting })
        }
        if (data.agreement === 'offer' && url) {
          dispatch(setAgreementsOffer(url))
        }
        if (data.agreement === 'purchase' && url) {
          dispatch(setAgreementsPurchase(url))
        }
        if (data.agreement === 'return' && url) {
          dispatch(setAgreementsReturn(url))
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      dispatch(setUploadSignatureLoading(false))
      if (!notCheck) startCheckSession()
    }
  }

  const deleteSignature = async (agreement: IUploadSignatureData['agreement'], items?: number[]) => {
    stopCheckSession()
    dispatch(setDeleteSignatureLoading(true))
    try {
      const resp = await callApi({
        method: 'DELETE',
        url: `${ApiRoutes.agreements}`,
        params: {
          agreement: agreement,
          ids: items,
        },
      })
      if (resp && resp.data && resp.data.success) {
        if (agreement === 'consignment') {
          dispatch(setAgreementsConsignment(null))
        }
        if (agreement === 'offer') {
          dispatch(setAgreementsOffer(null))
        }
        if (agreement === 'purchase') {
          dispatch(setAgreementsPurchase(null))
        }
        if (agreement === 'return') {
          dispatch(setAgreementsReturn(null))
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      dispatch(setDeleteSignatureLoading(false))
      startCheckSession()
    }
  }

  return {
    deleteSignature,
    uploadSignature,
  }
}
