import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IStore } from '../../../../types/storeTypes'
import { useFormSession } from '../../../../hooks/useFormSession'
import { Loader } from '../../../loader'
import { useOfferItem } from '../../../../hooks/useOfferItem'
import { getCurrency } from '../../../../utils/getCurrency'
import { DefaultButton } from '../../../defaultButton'
import { useOfferDocs } from '../../../../hooks/useOfferDocs'
import { ClientShortInfoBlock } from '../../../formBlocks/clientShortInfoBlock'
import parse from 'html-react-parser'
import { IUploadSignatureData } from '../../../../hooks/useOfferAgreements'
import { MessageModal } from '../../../modals/messageModal'
import { useGetConsignedStepData } from '../../../../hooks/useGetConsignedStepData'
import { defaultClient } from '../../../../utils/defaultClient'
import { SignatureBlock } from '../../../formBlocks/signatureBlock'
import { ISignatureTab } from '../../../../types/additionalTypes'

const tabs: ISignatureTab[] = [{ value: 'purchase', title: 'Purchase Agreement' }]

const clientRequiredFields = [
  'first_name',
  'last_name',
  'email',
  'phone_no',
  'country',
  'province',
  'municipality',
  'address1',
  'postal_code',
  'email',
]

const allRequiredFields = [...clientRequiredFields]

export const SignatureStep = () => {
  const { t } = useTranslation()
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const client = useSelector((store: IStore) => store.client)
  const agreements = useSelector((store: IStore) => store.agreements)
  const consignmentConversion = useSelector((store: IStore) => store.consignmentConversion)
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const { conversionItems } = consignmentConversion
  const { stepsLoading, changeCurrentStep, getPaymentStepData } = useGetConsignedStepData()
  const { saveConsignmentConversionSession } = useFormSession()
  const { getOfferItemTitle } = useOfferItem()
  const [saveLoading, setSaveLoading] = useState(false)
  const { loadPdf, loadPdfLoading } = useOfferDocs()
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const notes = appointmentInfo.intermediate_save?.decisionNotes || ''
  const [clientInfo, setStateClientInfo] = useState<IStore['client']>(defaultClient)
  const [buyer, setBuyer] = useState<number>(0)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [openErrorModal, setOpenErrorModal] = useState(false)

  const shouldCheckClient = true

  useEffect(() => {
    const newClient = { ...client }
    if (client.identity && client.identity.height) {
      newClient.height_type = client.identity.height.measure
      newClient.height_1 =
        client.identity.height.measure === 'Metric'
          ? client.identity.height.value.cm || 0
          : client.identity.height.value.ft || 0
      newClient.height_2 = client.identity.height.value.in || 0
    }
    setStateClientInfo(newClient)
  }, [client])

  useEffect(() => {
    if (appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.buyer_id) {
      setBuyer(appointmentInfo.intermediate_save.buyer_id)
    } else if (appointmentInfo && appointmentInfo.buyer_id) {
      setBuyer(appointmentInfo.buyer_id)
    }
  }, [appointmentInfo, appointmentInfo.buyer_id])

  const checkForErrors = () => {
    let fields = allRequiredFields
    const errors = fields
      .map((field) => {
        if (field === 'buyer') return !buyer ? field : ''
        else if (field === 'email') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'phone_no') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'postal_code') {
          if (!clientInfo.postal_code && clientInfo.country !== 'HK') return field
        } else if (field === 'province') {
          if (!clientInfo.province && ['CA', 'US'].includes(clientInfo.country)) return field
        } else if (field === 'id_state') {
          if (
            !clientInfo.id_state &&
            ['US'].includes(clientInfo.country) &&
            [`Driver's License`].includes(clientInfo.id_type)
          ) {
            return field
          }
        } else if (!clientInfo[field as keyof IStore['client']]) return field

        return ''
      })
      .filter((error) => !!error)

    if (errors.length > 0) {
      setFormErrors(errors)
      return false
    }

    return true
  }

  useEffect(() => {
    if (clientInfo.id) checkForErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo])

  const onSave = async () => {
    setSaveLoading(true)
    if (shouldCheckClient && !checkForErrors()) {
      setOpenErrorModal(true)
      setSaveLoading(false)
      return null
    }
    const nextStep = 4
    await getPaymentStepData()
    await saveConsignmentConversionSession({ currentStep: nextStep })
    changeCurrentStep(nextStep)
    setSaveLoading(false)
  }

  const onBack = () => {
    changeCurrentStep(currentStep - 1)
  }

  const purchaseAmount = useMemo(() => {
    return conversionItems.reduce((a, b) => {
      return a + (b.price ? +b.price : 0)
    }, 0)
  }, [conversionItems])

  const signatureFile = useMemo(() => {
    return agreements.purchase_form
  }, [agreements])

  const signatureAmount = useMemo(() => {
    return getCurrency(purchaseAmount, consignmentConversion.currency)
  }, [purchaseAmount, consignmentConversion.currency])

  const saveButtonTitle = `${t('Save and Continue')} →`

  return stepsLoading.includes(4) ||
    stepsLoading.includes(2) ||
    conversionItems.length <= 0 ||
    !offerDicts.offerDictsUploaded ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col gap-6'>
        <div className='flex w-full gap-2 justify-start overflow-auto custom_scroll pb-2'>
          {tabs.map((tab) => {
            return (
              <button
                key={`items-tab-${tab.value}`}
                type='button'
                onClick={() => setSelectedTab(tab.value)}
                className={`flex justify-center items-center text-lg font-medium rounded-lg py-2 h-9 w-[280px] min-w-[200px] ${tab.value === selectedTab ? 'text-white bg-green-700' : ' text-zinc-600 bg-white'}`}
              >
                {t(tab.title)}
              </button>
            )
          })}
        </div>

        <div className=''>
          <div className='text-base font-medium text-zinc-500 mb-2 flex justify-between px-3'>
            <div className=' w-[60%]'>
              <span className='mr-3'>#</span>
              {t('Item Descriptions')}
            </div>
            <div className='flex justify-end w-[100px] min-w-[60px]'>{t('Price')}</div>
          </div>

          <div className=' rounded-lg bg-white flex flex-col divide-y divide-zinc-200 max-h-[50vh] overflow-y-auto custom_scroll'>
            {conversionItems.map((item, index) => {
              return (
                <div key={`${item.type}-${index}`} className=' text-zinc-700 flex justify-between items-center px-3'>
                  <div className='flex items-center w-[60%] min-h-[68px]'>
                    <div className='text-[22px] pb-1'>{index + 1}</div>
                    <div className=' text-base font-medium px-3 line-clamp-2'>{getOfferItemTitle(item)}</div>
                  </div>
                  <div className='flex'>
                    <div className='text-lg font-medium flex items-center justify-end w-[100px] min-w-[60px]'>
                      {item.price ? getCurrency(item.price, consignmentConversion.currency) : ''}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='w-full flex flex-col md:flex-row justify-between items-start gap-6 md:gap-1 lg:gap-10 desktop:gap-20'>
          <div className='flex flex-col gap-4 w-full md:w-auto'>
            <ClientShortInfoBlock
              client={clientInfo}
              setClientInfo={setStateClientInfo}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              requiredFields={shouldCheckClient ? allRequiredFields : []}
              isSignature
              disableEdit={!shouldCheckClient}
              isGlobalSave
              buyer={buyer}
            />
          </div>

          <div className='flex flex-col gap-6 px-4 w-full md:w-auto'>
            <div className='flex justify-end text-lg font-medium text-zinc-700'>
              {t('Total Purchase Amount') + ' ' + getCurrency(purchaseAmount, consignmentConversion.currency)}
            </div>

            <div className='text-base font-normal text-zinc-700 flex justify-end'>
              {parse(t('signature_purchase_agreement'))}
            </div>

            <SignatureBlock
              selectedTab={selectedTab as IUploadSignatureData['agreement']}
              signatureFile={signatureFile}
              signatureAmount={signatureAmount}
              saveLoading={saveLoading}
              tabs={tabs}
              emailBtn={true}
              isConsignedConvert
            />
          </div>
        </div>

        <div className='flex justify-between items-center mb-5 mt-12 md:mt-12 relative'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[200px]'
            disabled={saveLoading || loadPdfLoading || agreements.uploadSignatureLoading}
          />

          {selectedTab === 'offer' && (
            <div className='flex items-center justify-center gap-4 absolute w-full md:w-auto top-[-60px] md:top-0 md:relative'>
              <DefaultButton
                title={t('Print')}
                onClick={() => loadPdf('offer', undefined, notes)}
                classname='grey_button'
                loading={loadPdfLoading}
                disabled={saveLoading || loadPdfLoading || agreements.uploadSignatureLoading}
                icon='print'
              />
            </div>
          )}

          <DefaultButton
            title={saveButtonTitle}
            onClick={onSave}
            classname='main_button min-w-[200px]'
            loading={saveLoading}
            disabled={
              saveLoading ||
              loadPdfLoading ||
              agreements.uploadSignatureLoading ||
              !agreements.purchase_form ||
              (shouldCheckClient && formErrors.length > 0)
            }
          />
        </div>
      </div>

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
        errors={formErrors}
      />
    </>
  )
}
