import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../../../types/storeTypes'
import { IconNoData } from '../../../../images/IconNoData'
import { DefaultButton } from '../../../defaultButton'
import { Loader } from '../../../loader'
import { useFormSession } from '../../../../hooks/useFormSession'
import { IOfferItem } from '../../../../types/offerTypes'
import { IconDelete } from '../../../../images/IconDelete'
import { MessageModal } from '../../../modals/messageModal'
import { Tooltip } from 'react-tooltip'
import { IconPlus } from '../../../../images/IconPlus'
import { useGetAcquiredReturnStepData } from '../../../../hooks/useGetAcquiredReturnStepData'
import { setAcquiredReturnItems } from '../../../../store/acquiredReturnSlice'
import { AddAcquiredReturnItemsModal } from '../../../modals/addAcquiredReturnItemsModal'
import { getCurrency } from '../../../../utils/getCurrency'
import { AcquiredReturnItemsConfirmModal } from '../../../modals/acquiredReturnItemsConfirmModal'
import { IAcquiredReturnItem } from '../../../../types/acquiredReturnTypes'

interface Props {}

export const SecondStep: React.FC<Props> = () => {
  const { t } = useTranslation()
  const acquiredReturn = useSelector((store: IStore) => store.acquiredReturn)
  const { returnItems } = acquiredReturn
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const [saveLoading, setSaveLoading] = useState(false)
  const { stepsLoading, changeCurrentStep, getSignatureStepAgreements } = useGetAcquiredReturnStepData()
  const dispatch = useDispatch()
  const { saveSession } = useFormSession()
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [showConfirmItemsModal, setShowConfirmItemsModal] = useState(false)

  const tooltipStyle = 'max-w-[350px] z-10 text-sm font-normal'
  const tooltipDelay = 400

  const returnItemsConfirm = async (itemsForReturn: IAcquiredReturnItem[], returnAll?: boolean) => {
    setSaveLoading(true)
    sessionStorage.removeItem('2_step_items')
    const { intermediate_save } = appointmentInfo
    const acquiredReturnData =
      !!intermediate_save && !!intermediate_save?.acquiredReturn ? intermediate_save?.acquiredReturn : {}
    const saveData = {
      ...intermediate_save,
      offerId: acquiredReturn.id,
      acquiredReturn: {
        ...acquiredReturnData,
        currentStep: currentStep + 1,
        returnItems: itemsForReturn,
        returnAllItems: returnAll,
      },
    }
    dispatch(setAcquiredReturnItems(itemsForReturn))
    await saveSession(saveData)
    await getSignatureStepAgreements(itemsForReturn.map((itm) => itm.id))
    changeCurrentStep(currentStep + 1)
    setSaveLoading(false)
  }

  const onSave = () => {
    setSaveLoading(true)
    if (returnItems.length === acquiredReturn.items.length) {
      const newItems = returnItems.map((item) => {
        const nsItems = acquiredReturn.stockItems
          .filter((stockItem) => stockItem.items_id.includes(item.id))
          .map((stockItem2) => stockItem2.id)
        return {
          ...item,
          ns_items: nsItems,
        }
      })
      returnItemsConfirm(newItems, true)
    } else {
      setShowConfirmItemsModal(true)
      setSaveLoading(false)
    }
  }

  const onBack = () => {
    changeCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    const step = appointmentInfo.intermediate_save?.acquiredReturn?.currentStep
      ? appointmentInfo.intermediate_save?.acquiredReturn?.currentStep
      : 0
    if (!returnItems.length && step <= 2) {
      const fromStorage = sessionStorage.getItem('2_step_items')
      const items =
        appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.acquiredReturn?.returnItems
          ? appointmentInfo.intermediate_save.acquiredReturn?.returnItems
          : fromStorage
            ? (JSON.parse(fromStorage) as IOfferItem[])
            : []
      if (!!items.length) {
        dispatch(setAcquiredReturnItems(items))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentInfo.intermediate_save])

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Element)) {
      setShowSettings(false)
    }
  }, [])

  useEffect(() => {
    if (showSettings) {
      window.addEventListener('mousedown', handleClickOutside)
    } else {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showSettings, handleClickOutside])

  useEffect(() => {
    if (returnItems.length) {
      sessionStorage.setItem('2_step_items', JSON.stringify(returnItems))
      const amount = returnItems.reduce((a, b) => {
        return a + (b.amount ? +b.amount : 0)
      }, 0)
      setTotalAmount(amount)
    } else {
      sessionStorage.removeItem('2_step_items')
      sessionStorage.removeItem('2_step_startValue')
      setTotalAmount(0)
    }
  }, [returnItems])

  const onItemDelete = (index: number) => {
    sessionStorage.removeItem('2_step_startValue')
    const newArr = [...returnItems]
    if (newArr.length > 1) {
      newArr.splice(index, 1)
      dispatch(setAcquiredReturnItems(newArr))
    } else {
      dispatch(setAcquiredReturnItems([]))
    }
  }

  const itemsForSelect = useMemo(() => {
    return acquiredReturn.items
      ? acquiredReturn.items.filter((item) => !returnItems.some((returnItem) => returnItem.id === item.id))
      : []
  }, [acquiredReturn.items, returnItems])

  return stepsLoading.includes(currentStep) ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col'>
        {!!returnItems.length ? (
          <div className=''>
            <div className='text-base font-medium text-zinc-500 mb-2 flex justify-between px-3'>
              <div className=''>
                <span className='mr-3'>#</span>
                {t('Item Descriptions')}
              </div>

              <div className='flex pr-[60px]'>
                <div className='text-end'>{t('Price')}</div>
              </div>
            </div>

            <div className=' rounded-lg bg-white flex flex-col divide-y divide-zinc-200 max-h-[50vh] overflow-y-auto custom_scroll'>
              {returnItems.map((item, index) => {
                return (
                  <div
                    key={`${item.id}-${index}`}
                    className=' text-zinc-700 flex justify-between items-center px-3 min-h-[68px] h-[68px]'
                  >
                    <div className='flex items-center'>
                      <div className='text-[22px] pb-1'>{index + 1}</div>
                      <div className=' text-base font-medium px-3 line-clamp-2'>{item.description}</div>
                    </div>
                    <div className='flex'>
                      <div className='text-lg font-medium flex items-center justify-end min-w-[60px]'>
                        {item.amount ? getCurrency(item.amount, item.currency) : ''}
                      </div>
                      <div className='w-[60px] flex justify-end pl-3 h-full'>
                        <button
                          type='button'
                          className='px-2 py-2 group'
                          onClick={() => onItemDelete(index)}
                          data-tooltip-id={`button-delete_item-${index}`}
                        >
                          <IconDelete classname='text-zinc-500 group-hover:text-zinc-700' />
                        </button>
                        <Tooltip id={`button-delete_item-${index}`} className={tooltipStyle} delayShow={tooltipDelay}>
                          {t('Delete item')}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div className='w-full bg-white rounded-lg h-[50vh] overflow-y-auto custom_scroll flex justify-center items-center'>
            <div className='flex flex-col justify-center items-center'>
              <div className='text-[22px] text-zinc-800'>{t('Please add items')}</div>
              <IconNoData />
              <div className='text-lg text-zinc-400'>{t('No data')}</div>
            </div>
          </div>
        )}

        <div className='flex justify-between flex-col-reverse md:flex-row mt-6  mb-12'>
          <div className='flex justify-between flex-col desktop:flex-row gap-4'>
            {itemsForSelect.length > 0 ? (
              <button
                type='button'
                className='text-white text-base font-medium bg-green-700 md:w-[260px] py-[10px] px-4 rounded hover:bg-green-800 hover:shadow-lg flex justify-center items-center gap-2'
                onClick={() => setShowAddItemModal(true)}
                disabled={itemsForSelect.length <= 0}
              >
                <IconPlus /> {t('Select items to return')}
              </button>
            ) : null}
          </div>

          <div className='bg-white rounded font-medium text-[22px] flex items-center h-[42px] px-[70px] justify-center mb-6 md:mb-0'>
            <div>{t('Total Offer Amount')}</div>
            <div className='ml-3'>{getCurrency(totalAmount, appointmentInfo.intermediate_save?.currency)}</div>
          </div>
        </div>

        <div className='flex justify-between items-center mb-5 mt-8 md:mt-0 relative'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[230px]'
            disabled={saveLoading}
          />

          <DefaultButton
            title={`${t('Save and Continue')} →`}
            onClick={onSave}
            classname='main_button min-w-[230px]'
            loading={saveLoading}
            disabled={saveLoading || !returnItems.length}
          />
        </div>
      </div>

      <AddAcquiredReturnItemsModal
        showed={showAddItemModal}
        closeModal={() => setShowAddItemModal(false)}
        itemsForSelect={itemsForSelect}
      />

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
      />

      <AcquiredReturnItemsConfirmModal
        showed={showConfirmItemsModal}
        closeModal={() => setShowConfirmItemsModal(false)}
        onItemsSave={returnItemsConfirm}
      />
    </>
  )
}
