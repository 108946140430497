import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../../../types/storeTypes'
import { IconNoData } from '../../../../images/IconNoData'
import { IconPlus } from '../../../../images/IconPlus'
import { DefaultButton } from '../../../defaultButton'
import { Loader } from '../../../loader'
import { useFormSession } from '../../../../hooks/useFormSession'
import { ApiRoutes } from '../../../../utils/apiRoutes'
import { showGlobalPopup } from '../../../../store/globalPopupSlice'
import { callApi } from '../../../../utils/callApi'
import { AddItemsModal } from '../../../modals/addItemsModal'
import { IOfferItem } from '../../../../types/offerTypes'
import { setOfferItems } from '../../../../store/offerInfoSlice'
import { IconEdit } from '../../../../images/IconEdit'
import { IconCopy } from '../../../../images/IconCopy'
import { IconDelete } from '../../../../images/IconDelete'
import { useOfferItem } from '../../../../hooks/useOfferItem'
import { EditItemModal } from '../../../modals/editItemModal'
import { CopyFromExistingOfferModal } from '../../../modals/copyFromExistingOfferModal'
import { setOfferDictsCopiedItems } from '../../../../store/offerDictsSLice'
import { MessageModal } from '../../../modals/messageModal'
import { useOfferNotes } from '../../../../hooks/useOfferNotes'
import { Tooltip } from 'react-tooltip'
import { useOfferDocs } from '../../../../hooks/useOfferDocs'
import { useGetDropOffStepData } from '../../../../hooks/useGetDropOffStepData'

const requiredFields = ['additional_description', 'subproduct', 'image']

interface Props {}

export const SecondStep: React.FC<Props> = () => {
  const { t } = useTranslation()
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const { offerItems, items } = offerInfo
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const [saveLoading, setSaveLoading] = useState(false)
  const { stepsLoading, changeCurrentStep, getSignatureStepAgreements, getThirdStepData } = useGetDropOffStepData()
  const dispatch = useDispatch()
  const { stopCheckSession, saveSession } = useFormSession()
  const { offerDictsData } = offerDicts
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const [itemEdit, setItemEdit] = useState<{ item: IOfferItem; index: number } | null>(null)
  const { getOfferItemTitle } = useOfferItem()
  const [showCopyFromExistingOffer, setShowCopyFromExistingOffer] = useState(false)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const { createOfferNote } = useOfferNotes()
  const { loadPdfWithItems, loadPdfLoading } = useOfferDocs()
  const tooltipStyle = 'max-w-[350px] z-10 text-sm font-normal'
  const tooltipDelay = 400

  const onSave = async () => {
    setSaveLoading(true)
    stopCheckSession()
    const offer_id = offerInfo.id || appointmentInfo.intermediate_save?.offerId
    const copied = offerItems.filter(
      (item) =>
        !!item.id &&
        offerDicts.offerDictsData &&
        offerDicts.offerDictsData?.items_for_copy.some((offerItem) => offerItem.id === item.id)
    )

    const cb = async (resp: any) => {
      if (resp && resp.data) {
        const data = resp.data.data
        sessionStorage.removeItem('2_step_items')
        await getThirdStepData()
        await getSignatureStepAgreements()
        if (!offer_id && appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.internal_notes) {
          const newNotes = appointmentInfo.intermediate_save.internal_notes
          if (newNotes.length > 0) await createOfferNote(newNotes.map((note) => note.note))
        }
        await saveSession({
          currentStep: currentStep + 1,
          offerId: data.id,
          offerItems: data.items,
          internal_notes: [],
        })
        if (copied && copied.length > 0) {
          dispatch(
            setOfferDictsCopiedItems(
              offerDicts.offerDictsData?.items_for_copy.filter(
                (offerItem) => !copied.some((item) => item.id === offerItem.id)
              )
            )
          )
        }
        changeCurrentStep(currentStep + 1)
      }
    }

    if (offer_id) {
      const removed = items.filter((item) => !!item.id && !offerItems.some((offerItem) => offerItem.id === item.id))

      try {
        const data = {
          items: offerItems,
          buyer_id: appointmentInfo.buyer_id || appointmentInfo.intermediate_save?.buyer_id,
          remove_items_id: removed.map((item) => item.id),
        }
        const resp = await callApi({
          method: 'PUT',
          url: `${ApiRoutes.secondStep}`,
          data,
          isDropOff: true,
        })
        await cb(resp)
      } catch (error) {
        console.log(error, 'error data')
        dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
      } finally {
        setSaveLoading(false)
      }
    } else {
      try {
        const data = {
          items: offerItems,
          buyer_id: appointmentInfo.buyer_id || appointmentInfo.intermediate_save?.buyer_id,
        }
        const resp = await callApi({
          method: 'POST',
          url: `${ApiRoutes.secondStep}`,
          data,
          isDropOff: true,
        })
        cb(resp)
      } catch (error) {
        console.log(error, 'error data')
        dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
      } finally {
        setSaveLoading(false)
      }
    }
  }

  const onBack = () => {
    changeCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    const step = appointmentInfo.intermediate_save?.currentStep ? appointmentInfo.intermediate_save.currentStep : 0
    if (!offerItems.length && step <= 2) {
      const fromStorage = sessionStorage.getItem('2_step_items')
      const items =
        appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.offerItems
          ? appointmentInfo.intermediate_save?.offerItems
          : fromStorage
            ? (JSON.parse(fromStorage) as IOfferItem[])
            : []
      if (!!items.length) {
        dispatch(setOfferItems(items))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentInfo.intermediate_save])

  useEffect(() => {
    if (offerItems.length) {
      sessionStorage.setItem('2_step_items', JSON.stringify(offerItems))
    } else {
      sessionStorage.removeItem('2_step_items')
    }
  }, [offerItems])

  const onItemsSave = (items: IOfferItem[]) => {
    sessionStorage.removeItem('2_step_startValue')
    dispatch(setOfferItems(items))
  }

  const onCopyItemsSave = (items: IOfferItem[]) => {
    sessionStorage.removeItem('2_step_startValue')
    const copyItems = items.map((item) => {
      return {
        ...item,
        offer_made: true,
        copy_of_item_id: item.id,
        id: undefined,
        price: null,
      }
    })
    dispatch(setOfferItems([...offerItems, ...copyItems]))
  }

  const onItemDelete = (index: number) => {
    sessionStorage.removeItem('2_step_startValue')
    const newArr = [...offerItems]
    if (newArr.length > 1) {
      newArr.splice(index, 1)
      dispatch(setOfferItems(newArr))
    } else {
      dispatch(setOfferItems([]))
    }
  }

  const onItemCopy = (item: IOfferItem) => {
    sessionStorage.removeItem('2_step_startValue')
    dispatch(setOfferItems([...offerItems, { ...item, id: undefined }]))
  }

  const onItemEdit = (item: IOfferItem, index: number) => {
    setItemEdit({ item, index })
  }

  const onItemEditSave = (item: IOfferItem, index: number) => {
    sessionStorage.removeItem('2_step_startValue')
    const newArr = [...offerItems]
    newArr[index] = item
    dispatch(setOfferItems(newArr))
  }

  const itemsForCopy = useMemo(() => {
    if (!offerDictsData || !offerDictsData.items_for_copy) return []
    if (!offerItems.length) return offerDictsData.items_for_copy
    return offerDictsData.items_for_copy.filter((copyItem) => !offerItems.some((item) => item.id === copyItem.id))
  }, [offerDictsData, offerItems])

  return stepsLoading.includes(currentStep) ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col'>
        {!!offerItems.length ? (
          <div className=''>
            <div className='text-base font-medium text-zinc-500 mb-2 flex justify-between px-3'>
              <div className=''>
                <span className='mr-3'>#</span>
                {t('Item Descriptions')}
              </div>
            </div>

            <div className=' rounded-lg bg-white flex flex-col divide-y divide-zinc-200 max-h-[50vh] overflow-y-auto custom_scroll'>
              {offerItems.map((item, index) => {
                return (
                  <div
                    key={`${item.type}-${index}`}
                    className=' text-zinc-700 flex justify-between items-center px-3 min-h-[68px] h-[68px]'
                  >
                    <div className='flex items-center'>
                      <div className='text-[22px] pb-1'>{index + 1}</div>

                      <div className=' text-base font-medium px-3 line-clamp-2'>{getOfferItemTitle(item)}</div>
                    </div>

                    <div className='flex'>
                      <div className='w-[150px] flex justify-between pl-3 h-full'>
                        <button
                          type='button'
                          className='px-2 py-2 group'
                          onClick={() => onItemEdit(item, index)}
                          data-tooltip-id={`button-edit_item-${index}`}
                        >
                          <IconEdit classname='text-zinc-500 group-hover:text-zinc-700' />
                        </button>

                        <Tooltip id={`button-edit_item-${index}`} className={tooltipStyle} delayShow={tooltipDelay}>
                          {t('Edit item')}
                        </Tooltip>

                        <button
                          type='button'
                          className='px-2 py-2 group'
                          onClick={() => onItemCopy(item)}
                          data-tooltip-id={`button-copy_item-${index}`}
                        >
                          <IconCopy classname='text-zinc-500 group-hover:text-zinc-700' />
                        </button>

                        <Tooltip id={`button-copy_item-${index}`} className={tooltipStyle} delayShow={tooltipDelay}>
                          {t('Copy item')}
                        </Tooltip>

                        <button
                          type='button'
                          className='px-2 py-2 group'
                          onClick={() => onItemDelete(index)}
                          data-tooltip-id={`button-delete_item-${index}`}
                        >
                          <IconDelete classname='text-zinc-500 group-hover:text-zinc-700' />
                        </button>

                        <Tooltip id={`button-delete_item-${index}`} className={tooltipStyle} delayShow={tooltipDelay}>
                          {t('Delete item')}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div className='w-full bg-white rounded-lg h-[50vh] overflow-y-auto custom_scroll flex justify-center items-center'>
            <div className='flex flex-col justify-center items-center'>
              <div className='text-[22px] text-zinc-800'>{t('Please add items')}</div>
              <IconNoData />
              <div className='text-lg text-zinc-400'>{t('No data')}</div>
            </div>
          </div>
        )}

        <div className='flex justify-between flex-col-reverse md:flex-row mt-6'>
          <div className='flex justify-between flex-col desktop:flex-row gap-4 mb-12'>
            <button
              type='button'
              className='text-white text-base font-medium bg-green-700 md:w-[260px] py-[10px] px-4 rounded hover:bg-green-800 hover:shadow-lg flex justify-center items-center gap-2'
              onClick={() => setShowAddItemModal(true)}
            >
              <IconPlus /> {t('Add item')}
            </button>
            {itemsForCopy.length > 0 ? (
              <button
                type='button'
                className='text-green-600 text-base font-medium bg-white md:w-[260px] py-[10px] px-4 rounded border border-green-700 hover:bg-green-700 hover:text-white hover:shadow-lg'
                onClick={() => setShowCopyFromExistingOffer(true)}
              >
                {t('Copy from Existing Offer')} ({itemsForCopy.length})
              </button>
            ) : null}
          </div>
        </div>

        <div className='flex justify-between items-center mb-5 mt-8 md:mt-0 relative'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[230px]'
            disabled={saveLoading}
          />

          <div className='flex items-center gap-4 w-full justify-center absolute top-[-60px] md:top-0 md:relative'>
            <DefaultButton
              title={t('Print')}
              onClick={() => loadPdfWithItems({ items: offerItems, hide_price_items: true, hide_total_price: true })}
              classname='grey_button'
              loading={loadPdfLoading}
              disabled={saveLoading || loadPdfLoading || offerItems.length <= 0}
              icon='print'
            />
          </div>

          <DefaultButton
            title={`${t('Save and Continue')} →`}
            onClick={onSave}
            classname='main_button min-w-[230px]'
            loading={saveLoading}
            disabled={saveLoading || !offerItems.length}
          />
        </div>
      </div>

      <AddItemsModal
        showed={showAddItemModal}
        closeModal={() => setShowAddItemModal(false)}
        onItemsSave={onItemsSave}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        requiredFields={requiredFields}
        setOpenErrorModal={setOpenErrorModal}
        isDropOff={true}
      />

      <EditItemModal
        showed={!!itemEdit}
        closeModal={() => setItemEdit(null)}
        offerItem={itemEdit?.item || null}
        onItemSave={onItemEditSave}
        itemIndex={itemEdit?.index || 0}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        requiredFields={requiredFields}
        setOpenErrorModal={setOpenErrorModal}
        isDropOff={true}
      />

      <CopyFromExistingOfferModal
        itemsForCopy={itemsForCopy}
        showed={showCopyFromExistingOffer}
        closeModal={() => setShowCopyFromExistingOffer(false)}
        onItemsSave={onCopyItemsSave}
      />

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
      />
    </>
  )
}
