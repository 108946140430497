import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { IOfferItem } from '../../../types/offerTypes'
import { AddItemForm } from '../../addItemForm'
import { useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { Loader } from '../../loader'
import { MultiSelect } from '../../multiSelect'
import { currentYear, minWatchYear } from '../../../utils/consts'

interface Props {
  showed: boolean
  closeModal: () => void
  onItemsSave: (items: IOfferItem[]) => void
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
  setOpenErrorModal: (value: boolean) => void
  isDropOff?: boolean
  isConsignedConvert?: boolean
}

const defaultItem = {
  type: '',
  offer_made: true,
}

export const AddItemsModal: React.FC<Props> = ({
  showed,
  closeModal,
  onItemsSave,
  formErrors,
  setFormErrors,
  requiredFields,
  setOpenErrorModal,
  isDropOff = false,
  isConsignedConvert = false,
}) => {
  const { t } = useTranslation()
  const [items, setItems] = useState<IOfferItem[]>([])
  const [currentItem, setCurrentItem] = useState<IOfferItem>(defaultItem)
  const [swapItemLoading, setSwapItemLoading] = useState(false)
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const consignmentConversion = useSelector((store: IStore) => store.consignmentConversion)
  const { offerItems } = offerInfo
  const { offerDictsData } = offerDicts

  useEffect(() => {
    if (isConsignedConvert) {
      setItems(consignmentConversion.conversionItems)
    } else setItems(offerItems)
  }, [consignmentConversion.conversionItems, isConsignedConvert, offerItems, showed])

  const editField = (name: keyof IOfferItem, value: any) => {
    setFormErrors([])
    if (value === 'watch') {
      const subproduct = subproductsList.find((type) => type.id === 3)
      setCurrentItem({ ...defaultItem, [name]: value, subproduct: subproduct ? subproduct.id : undefined })
    } else {
      setCurrentItem({ ...defaultItem, [name]: value })
    }
  }

  const subproductsList = useMemo(
    () =>
      offerDictsData?.subproducts
        ? offerDictsData.subproducts.map((subproduct) => {
            return { label: subproduct.name, id: subproduct.id, value: subproduct.id }
          })
        : [],
    [offerDictsData]
  )

  const itemTypesList = useMemo(
    () =>
      offerDictsData?.item_types
        ? Object.entries(offerDictsData.item_types)
            .map((type) => {
              return { label: type[1], id: type[0], value: type[0] }
            })
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [offerDictsData]
  )

  const defaultItemType = useMemo(() => {
    return currentItem.type ? itemTypesList.find((listItem) => listItem.value === currentItem.type) : null
  }, [currentItem.type, itemTypesList])

  const onClose = () => {
    setFormErrors([])
    onItemsSave(items)
    setCurrentItem(defaultItem)
    closeModal()
  }

  const checkForErrors = () => {
    const errors = requiredFields
      .map((field) => {
        if (field === 'additional_description') {
          if (
            ['scrap', 'intrinsic', 'melee', 'other'].includes(currentItem.type) &&
            !currentItem.additional_description
          ) {
            return field
          } else {
            return ''
          }
        } else if (field === 'subproduct') {
          if (
            !['scrap', 'intrinsic', 'melee', 'loose', 'other'].includes(currentItem.type) &&
            !currentItem.subproduct
          ) {
            return field
          } else {
            return ''
          }
        } else if (!currentItem[field as keyof IOfferItem]) return field

        return ''
      })
      .filter((error) => !!error)

    if (currentItem.type === 'watch') {
      if (currentItem.full_year && (currentItem.full_year > currentYear || currentItem.full_year < minWatchYear)) {
        errors.push('full_year')
      }
      if (currentItem.full_year && !currentItem.year_type) {
        errors.push('year_type')
      }
    }

    if (errors.length > 0) {
      setFormErrors(errors)
      setOpenErrorModal(true)
      return false
    }

    return true
  }

  const onSave = () => {
    if (!checkForErrors()) return
    const newItem: IOfferItem = {
      ...currentItem,
      approx_tot_col_stone_ct_wt: currentItem.approx_tot_col_stone_ct_wt
        ? +currentItem.approx_tot_col_stone_ct_wt
        : undefined,
      approx_total_dia_ct_weight: currentItem.approx_total_dia_ct_weight
        ? +currentItem.approx_total_dia_ct_weight
        : undefined,
    }
    setItems([...items, newItem])
    onItemsSave([...items, newItem])
    setCurrentItem(defaultItem)
    closeModal()
  }

  const onSaveAndDuplicate = () => {
    if (!checkForErrors()) return
    const newItem: IOfferItem = {
      ...currentItem,
      approx_tot_col_stone_ct_wt: currentItem.approx_tot_col_stone_ct_wt
        ? +currentItem.approx_tot_col_stone_ct_wt
        : undefined,
      approx_total_dia_ct_weight: currentItem.approx_total_dia_ct_weight
        ? +currentItem.approx_total_dia_ct_weight
        : undefined,
    }
    setSwapItemLoading(true)
    const lastItem = { ...newItem }
    setItems([...items, newItem])
    setCurrentItem(defaultItem)
    setTimeout(() => {
      setCurrentItem(lastItem)
      setSwapItemLoading(false)
    }, 300)
  }

  const onSaveAndNext = () => {
    const newItem: IOfferItem = {
      ...currentItem,
      approx_tot_col_stone_ct_wt: currentItem.approx_tot_col_stone_ct_wt
        ? +currentItem.approx_tot_col_stone_ct_wt
        : undefined,
      approx_total_dia_ct_weight: currentItem.approx_total_dia_ct_weight
        ? +currentItem.approx_total_dia_ct_weight
        : undefined,
    }
    if (!checkForErrors()) return
    setSwapItemLoading(true)
    setItems([...items, newItem])
    setTimeout(() => {
      setCurrentItem(defaultItem)
      setSwapItemLoading(false)
    }, 300)
  }

  return (
    <Modal showed={showed} bodyStyle='w-[1080px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>
      <div className='flex flex-col gap-6 pt-4'>
        <div className='w-full pr-[13px] md:pr-2'>
          <div className='w-full md:w-2/3 lg:w-1/2 flex items-center md:pr-12'>
            <label
              htmlFor='input-item-type'
              className={`w-1/3 flex font-medium text-[28px] leading-9 items-end capitalize pr-2`}
            >
              {t('Add item')}
              <span className=' text-blue-500 text-xl ml-2'>#{items.length + 1}</span>
            </label>
            <div className='w-2/3'>
              <MultiSelect
                selectId='select-item-type'
                inputId='input-item-type'
                options={itemTypesList}
                onChange={(value) => editField('type', value)}
                value={defaultItemType}
                containerStyles='w-full h-[38px]'
                controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                controlDefaultStyles='form-offer-item-select'
                inputStyles='!text-zinc-900'
              />
            </div>
          </div>
        </div>

        <div className='max-h-[70vh] overflow-y-auto custom_scroll block pr-2'>
          {swapItemLoading ? (
            <div className='w-full min-h-[50vh] flex justify-center items-center'>
              <Loader className='h-[100px]' />
            </div>
          ) : (
            <>
              <AddItemForm
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                requiredFields={requiredFields}
                isDropOff={isDropOff}
              />

              <div className='flex flex-col md:flex-row justify-between items-center mt-6'>
                <button type='button' className='text_button' onClick={onSaveAndDuplicate} disabled={!currentItem.type}>
                  {t('Save & Duplicate item')}
                </button>
                <div className='flex flex-col md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
                  <button type='button' className='text_button' onClick={onSaveAndNext} disabled={!currentItem.type}>
                    {t('Save & Add next item')}
                  </button>
                  <button
                    type='button'
                    className='main_button w-full md:w-[200px]'
                    onClick={onSave}
                    disabled={!currentItem.type}
                  >
                    {t('Save')}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}
